import { Component } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';

// device

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  event$
  user = null;

  constructor(
    private router: Router,
    private navCtrl: NavController,
  ) {
    this.initializeApp()
  }

  async initializeApp() {

    // this.DEVICE.splashScreenHide()

    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if(event instanceof NavigationStart) {
        // das problem hierbei ist:
        // wenn eingeloggt in dann ist / gleich 'home'
        // wenn NICHT eingeloggt dann ist / gleich 'user-login'
        // aus diesem Grunde ist bei appstart die 'user-login' seite mit dunkler statusbar (weil home)
        // PROBLEM: es gibt in production keine verlässliche art den PageName (nicht url) zu bekommen!
        // LÖSUNG: in 'user-login' wird setupPage('/user-login') ausgeführt um die statusbar richtig zu setzen
        
        // console.log('app-component: ', event.url)
        // this.DEVICE.setupPage(event.url)
        console.log('#', event.url.toString())

        if (event.url.toString() != '/' && event.url.toString().indexOf('/login') == -1 && event.url.toString().indexOf('/enter') == -1)
        //console.log('##', event.url.toString())
        this.__checkLoggedIn()
      }
    });


  }


  async __checkLoggedIn() {
    
    let user = this.user

    //this.subscriber('user', this.AUTH.user.subscribe((user) => {
      if (user != null) {
        // console.log('app-component: checkLoggedIn() --> onAuthStateChanged() --> GOTO /home')

      }
      else {
        if (this.router.url.indexOf('/login') == -1) {
          console.log('app-component: USER IS NULL --> GOTO /login')
          this.navCtrl.navigateRoot('/login')
        }
      }
    //}))

  }

}
