import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { QuillModule, QuillModules } from 'ngx-quill';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

const modules: QuillModules = {
  toolbar: [
    [{ 'font': [] }, { 'size': [] }],
    [ 'bold', 'italic', 'underline', 'strike' ],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'script': 'super' }, { 'script': 'sub' }],
    // [{ 'header': 1 }, { 'header': 2 }, 'blockquote', 'code-block' ],
    [{ 'header': 1 }, { 'header': 2 }, 'blockquote' ],
    [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
    [ 'direction', { 'align': [] }],
    [ 'link', 'image', 'video', 'formula' ],
    [ 'clean' ],
    [ 'fullscreen' ]
    //[{ 'align': ''}, { 'align': 'center'}, {'align': 'right'}],
  ],
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    QuillModule.forRoot({
      modules,
      format: 'html',
      placeholder: 'Ihr Text'
    }),
    HttpClientModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, HTTP],
  bootstrap: [AppComponent],
})
export class AppModule {}
