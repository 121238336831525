import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule) },
  { path: 'login/:param1', loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule) },
  
  {
    path: 'eingabe',
    loadChildren: () => import('./modal/eingabe/eingabe.module').then( m => m.EingabePageModule)
  },
  {
    path: 'enter',
    loadChildren: () => import('./enter/enter.module').then( m => m.EnterPageModule)
  },

  { path: '', redirectTo: 'enter', pathMatch: 'full' },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
